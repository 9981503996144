export class DecimalNumberUtil {
  public static round(value: number, precision = 0): number {
    if (!value) {
      return 0;
    }

    return Math.round(value * 10 ** precision) / 10 ** precision;
  }

  public static floor(value: number, precision = 0): number {
    if (!value) {
      return 0;
    }

    return Math.floor(value * 10 ** precision) / 10 ** precision;
  }

  public static isDivisibleWithoutRemainder(dividend: number, divider: number): boolean {
    if (divider === 0) {
      return false;
    }

    return Number.isInteger(
        DecimalNumberUtil.round(dividend / divider, 3),
    );
  }

  public static isNumbersMultiple(
    firstNumber: number,
    secondNumber: number,
    precision = 3,
  ): boolean {
    return DecimalNumberUtil.round(
      DecimalNumberUtil.round(firstNumber, precision) % DecimalNumberUtil.round(secondNumber, precision),
      precision,
    ) === 0;
  }
}
